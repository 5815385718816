<template>
  <div></div>
</template>

<script>
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import VueEcho from 'vue-echo-laravel';
import Vue from 'vue';

export default {
  name: 'WebsocketLaravelEcho',
  watch: {
    '$store.state.auth.user': function () {
      this.$store.state.auth.user !== null ? this.connect() : this.disconnect();
    }
  },
  mounted() {
    this.connect();
  },
  beforeDestroy() {
    if (!this.$echo) return;
    this.$echo.disconnect();
  },
  methods: {
    /** Connect Echo **/
    async connect() {
      const token = this.$store.state.auth.token;

      if (!this.$echo) {
        const EchoInstance = new Echo({
          broadcaster: 'pusher',
          authEndpoint: process.env.VUE_APP_WEBSOCKET_AUTH_URL + '/broadcasting/auth',
          auth: {
            headers: {
              Authorization: 'Bearer ' + token
            }
          },
          key: 'myappkey',
          wsHost: process.env.VUE_APP_WEBSOCKET_BASE_URL,
          wsPort: 6001,
          forceTLS: false,
          disableStats: false
        });

        Vue.use(VueEcho, EchoInstance);
      } else {
        // console.log(this.$echo.connector.pusher.connection.state);
        if (this.$echo.connector.pusher.connection.state === 'disconnected') {
          this.$echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + token;
          this.$echo.connect();
        }
      }
    }
  }
};
</script>
