export default {
  mounted() {
    // Listen for the 'NewBlogPost' event in the 'team.1' private channel
    this.$echo.private('App.Models.User.' + this.$store.getters.currentUser.id).notification((notification) => {
      if (notification.type == 'transfer.received') {
        this.$swal({
          title: notification.title,
          icon: 'success',
          html: notification.message,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: true,
          backdrop: `
          rgba(0,0,123,0.4)
          url("https://sweetalert2.github.io/images/nyan-cat.gif")
          left top
          no-repeat
        `
        });
        this.$store.dispatch('VERIFY_AUTH');
      }
    });
  }
};
