<template>
  <div>
    <div v-if="currentStatus.id != 1" class="alert alert-custom alert-danger" role="alert">
      <div class="alert-icon"><i class="flaticon-warning"></i></div>
      <div class="alert-text">
        Sua conta de encontra inativa. Para realizar a ativação clique
        <router-link :to="{ name: 'profile-documents' }" class="text-warning">AQUI</router-link>
        confirme sua titularidade enviando seus documentos.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['currentStatus'])
  }
};
</script>

<style></style>
