<template>
  <div v-if="isAuthenticated" class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->
    <WebsocketLaravelEcho />

    <Loader v-if="loaderEnabled" :logo="loaderLogo"></Loader>
    <!-- <Socket /> -->
    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader v-if="subheaderDisplay" :breadcrumbs="breadcrumbs" :title="pageTitle" />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <div class="d-lg-flex flex-row-fluid">
                <!-- begin:: Aside Left -->
                <KTAside v-if="asideEnabled"></KTAside>
                <!-- end:: Aside Left -->
                <div class="content-wrapper flex-row-fluid">
                  <!-- begin:: Alert Documents  -->
                  <AlertActiveAccount />
                  <!-- end::Alert Documents-->
                  <transition name="fade-in-up">
                    <router-view />
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>

    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Loader from '@/shared/content/Loader.vue';
import KTAside from '@/shared/layout/aside/Aside.vue';
import KTHeader from '@/shared/layout/header/Header.vue';
import KTHeaderMobile from '@/shared/layout/header/HeaderMobile.vue';
import KTFooter from '@/shared/layout/footer/Footer.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import KTSubheader from '@/shared/layout/subheader/Subheader.vue';
import AlertActiveAccount from '@/shared/layout/alert/AlertActiveAccount.vue';
import KTScrollTop from '@/shared/layout/extras/ScrollTop';
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/core/services/store/modules/htmlclass';

import WebsocketLaravelEcho from '@/shared/websocket/WebsocketLaravelEcho';
import DepositNotifications from '@/core/mixins/websocket/DepositNotifications';
import InvoiceNotification from '@/core/mixins/websocket/InvoiceNotification';
import TransferNotifications from '@/core/mixins/websocket/TransferNotifications';

export default {
  name: 'Layout',
  components: {
    WebsocketLaravelEcho,
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    AlertActiveAccount,
    KTScrollTop,
    Loader
  },
  mixins: [DepositNotifications, InvoiceNotification, TransferNotifications],
  computed: {
    ...mapGetters(['isAuthenticated', 'breadcrumbs', 'pageTitle', 'layoutConfig']),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid';
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return this.layoutConfig('loader.logo');
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display');
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display');
    }
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 2000);
  }
};
</script>
